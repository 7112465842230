import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useGetCourseByIdQuery,
  useGetInvitationsQuery,
  useInviteToCourseMutation,
  useLazyGetCourseLinkQuery,
} from "../apis/coursesAPI";
import { selector } from "../store";
import { getOrganisation } from "../slices/organisationSlice";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Link,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useListPeopleQuery } from "../apis/rolesAPI";
import { ICourse, ICourseInvitation } from "@backend/model/course";
import Check from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import colors from "../colors";
import { theme } from "../theme";
import { ViewContainer } from "./components/viewContainer";
import { useTranslation } from "react-i18next";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import config from "../config";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

export const CourseInvitations = () => {
  const org = selector((state) => getOrganisation(state));
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { data: course, isFetching: loadingCourse } = useGetCourseByIdQuery(
    {
      organisationId: org?.organisationId || "",
      courseId: state?.courseId,
    },
    {
      skip: !state?.courseId || !org?.organisationId,
    }
  );
  const { data: invitations, isFetching: loadingInvitations } =
    useGetInvitationsQuery(
      {
        courseId: state?.courseId,
        organisationId: org?.organisationId || "",
      },
      {
        skip: !state?.courseId || !org?.organisationId,
      }
    );
  const { data: people, isFetching: loadingPeople } = useListPeopleQuery(org!, {
    skip: !org,
  });

  const [invite] = useInviteToCourseMutation();
  const [getLink] = useLazyGetCourseLinkQuery();
  const qrCodeRef = useRef<any>(null);

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [publicLink, setPublicLink] = useState("");

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);

  const createPublicLink = useCallback(async () => {
    const link = await getLink({
      courseId: state?.courseId,
      organisationId: org?.organisationId || "",
      userId: config.publicCourseUserId,
    }).unwrap();
    setPublicLink(link.url);
  }, [getLink, state, org]);

  const downloadQRCode = useCallback(() => {
    htmlToImage
      .toPng(qrCodeRef.current)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "qr-code.png";
        link.click();
      })
      .catch(function (error) {
        console.error("Error generating QR code:", error);
      });
  }, []);

  const sendInvitations = useCallback(
    async (mailStr: string) => {
      const emails = mailStr.replaceAll(", ", ",").split(",");
      await invite({
        organisationId: org?.organisationId || "",
        courseId: state?.courseId,
        emails,
      }).unwrap();
    },
    [state, org, invite]
  );

  const CompletedStatus = (params: GridCellParams) => {
    return params.row.status && params.row.completed ? (
      <Check color="success" />
    ) : (
      <Error color="error" />
    );
  };

  const ConvertedDate = (params: GridCellParams) => {
    return (
      <Typography style={{ fontSize: 14 }}>
        {params.row.completed
          ? dayjs(params.row.completed).format("DD.MM.YYYY")
          : t("Unfinished")}
      </Typography>
    );
  };

  const data: GridRowsProp =
    invitations?.map((i) => {
      return {
        id: i.userId,
        name: `${i.user?.firstName} ${i.user?.lastName}`,
        status: i.isCompleted,
        completed: i.lastCompleted,
      };
    }) || [];

  const columns: GridColDef[] = useMemo(() => {
    let result = [
      { field: "name", headerName: t("name"), width: 300 },
      {
        field: "status",
        headerName: t("status"),
        width: 120,
        renderCell: CompletedStatus,
      },
      {
        field: "completed",
        headerName: t("completed"),
        width: 120,
        renderCell: ConvertedDate,
      },
    ];

    return result;
  }, [t]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  const peopleOptions = useMemo(
    () =>
      people?.map(
        (p) =>
          ({
            value: p.id,
            label: `${p.firstName} ${p.lastName}`,
          } as any)
      ) || [],
    [people]
  );

  return (
    <ViewContainer loading={loadingInvitations || loadingPeople || !org}>
      <Box flex={1} position={"relative"}>
        <Typography variant="h4" sx={{ marginBottom: theme.spacing(2) }}>
          {course?.name}
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginBottom: theme.spacing(2), color: colors.textSecondary }}
        >
          {course?.description}
        </Typography>
        {course?.recurrency && (
          <Box flexDirection={"row"} display={"flex"}>
            <Typography
              variant="body1"
              sx={{
                marginBottom: theme.spacing(2),
                marginRight: theme.spacing(2),
              }}
            >
              {t("recurrency")}
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBottom: theme.spacing(2), fontWeight: "bold" }}
            >
              {course?.recurrency}
            </Typography>
          </Box>
        )}
        <Button
          variant="text"
          style={{
            position: "absolute",
            top: theme.spacing(2),
            right: theme.spacing(2),
          }}
          endIcon={<QrCodeScannerIcon />}
          onClick={createPublicLink}
        >
          {t("generatePublicLink")}
        </Button>

        <Divider />

        <Typography variant="h6">{t("invitePeople")}</Typography>
        <Autocomplete
          disablePortal
          id="combo-box"
          options={peopleOptions}
          sx={{ width: 300, marginTop: theme.spacing(1) }}
          renderInput={(params) => <TextField {...params} label={t("name")} />}
          onChange={(e: any, value: any) => {
            if (!value) {
              setEmail("");
              setPhoneNumber("");
            } else {
              const user = people?.find((p) => p.id === value.value);
              setEmail(user?.email || "");
              setPhoneNumber(user?.phoneNumber || "");
            }
          }}
        />
        <Button
          style={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
          }}
          variant="contained"
          disabled={!email}
          onClick={() => sendInvitations(email)}
        >
          {t("send")}
        </Button>
      </Box>

      {/* Data grid for invitations */}
      <DataGrid
        sx={{
          width: "calc(100vw - 100px)",
          borderStyle: "none",
          height: "75%",
          ".MuiDataGrid-cell": {
            borderColor: "#555",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
        }}
        //checkboxSelection
        rows={data}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          // Selecting a checkbox saves the email of every selected row on the list to rowSelectionModel-state
          setRowSelectionModel(newRowSelectionModel);
        }}
        columns={columns}
        onRowClick={() => {}}
        disableRowSelectionOnClick
        slots={{ toolbar: CustomToolbar }}
      />

      <Modal
        open={!!publicLink}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => setPublicLink("")}
      >
        <Card
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "80%",
            margin: theme.spacing(3),
            padding: theme.spacing(3),
            background: colors.backgroundSecondary,
          }}
        >
          <Link
            href={publicLink}
            rel="noreferrer"
            target="_blank"
            style={{
              textAlign: "center",
              marginRight: theme.spacing(3),
              marginLeft: theme.spacing(3),
            }}
          >
            {publicLink}
          </Link>
          <IconButton
            onClick={downloadQRCode}
            size="medium"
            style={{
              position: "absolute",
              top: theme.spacing(2),
              right: theme.spacing(2),
            }}
          >
            <DownloadIcon />
          </IconButton>
          <Box
            ref={qrCodeRef}
            style={{
              background: "white",
              marginTop: theme.spacing(2),
              padding: theme.spacing(2),
            }}
          >
            <QRCode value={publicLink} size={400} />
          </Box>
        </Card>
      </Modal>
    </ViewContainer>
  );
};
