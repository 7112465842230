// Style utils

export const numberOfLines = (lines: number) => {
  return {
    display: "-webkit-box",
    WebkitLineClamp: lines,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  };
};

// 768 seems to be a common breakpoint for mobile devices
// 768-1023 for tablets
// > 1024 for desktop

export const mobileWidth = "(max-width:768px)"

