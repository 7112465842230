import React, { useCallback, useMemo, useState } from "react";
import {
  useDeleteCourseMutation,
  useListCoursesQuery,
} from "../apis/coursesAPI";
import { selector } from "../store";
import { getOrganisation } from "../slices/organisationSlice";
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  CardActionArea,
  Snackbar,
  Alert,
  Modal,
  Tooltip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../theme";
import { ImageContent } from "./components/slideImageContent";
import colors from "../colors";
import { numberOfLines } from "../styleUtils";
import { Delete, Edit, Group, PlayArrow } from "@mui/icons-material";
import { ICourse } from "@backend/model/course";
import { ViewContainer } from "./components/viewContainer";
import { useTranslation } from "react-i18next";

export const Courses = () => {
  const org: any = selector((state) => getOrganisation(state));
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [deleteCourseId, setDeleteCourseId] = useState<ICourse | undefined>(
    undefined
  );

  const navigate = useNavigate();
  const [deleteCourse, { isLoading: deleteCourseLoading }] =
    useDeleteCourseMutation();
  const { data: courses, isLoading: coursesLoading } = useListCoursesQuery(
    org?.organisationId,
    {
      skip: !org,
    }
  );

  const onDelete = useCallback(
    async (id: string) => {
      if (org) {
        try {
          await deleteCourse({
            organisationId: org.organisationId,
            courseId: id,
          });
        } catch (err) {
          setError(true);
        }
      }
    },
    [org, deleteCourse]
  );

  return (
    <ViewContainer loading={!org || coursesLoading || deleteCourseLoading}>
      <Box width={"100%"} height={"100%"}>
        <Snackbar
          open={error}
          autoHideDuration={3000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => setError(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {t("errorOccured")}
          </Alert>
        </Snackbar>
        <Typography variant="h4">{t("coursesTitle")}</Typography>
        <Button variant="text" onClick={() => navigate("/courses/create")}>
          {t("newCourse")}
        </Button>
        <Grid
          container
          spacing={theme.spacing(4)}
          style={{ paddingLeft: theme.spacing(2) }}
        >
          {courses &&
            courses.map((c) => (
              <Grid
                key={c.id}
                item
                xs={12} // full width cards on <600px screens
                sm={6} // 2 cards in a row on small screens
                md={4} // 3 cards in a row on medium screens
                lg={3} // 4 cards in a row on large screens
                style={{
                  marginTop: theme.spacing(4),
                  overflow: "hidden",
                  padding: theme.spacing(2),
                }}
              >
                <Card
                  style={{
                    paddingBottom: theme.spacing(2),
                    position: "relative",
                  }}
                >
                  <CardActionArea
                    sx={{ borderRadius: 0 }}
                    onClick={() =>
                      navigate(
                        `/courses/play?courseId=${c.id}&organisationId=${org.organisationId}`
                      )
                    }
                  >
                    <Card
                      style={{
                        padding: theme.spacing(2),
                        paddingBottom: 0,
                        position: "relative",
                        borderRadius: 0,
                        borderWidth: 0,
                      }}
                    >
                      <Typography variant="h6">{c.name}</Typography>
                      <Typography variant="body2" sx={numberOfLines(1)}>
                        {c.description || "\u2002"}
                      </Typography>
                      <Box
                        height={150}
                        bgcolor={"#000"}
                        marginLeft={theme.spacing(-2)}
                        marginRight={theme.spacing(-2)}
                        marginTop={theme.spacing(2)}
                      >
                        <ImageContent
                          content={c.image}
                          forcedFillMode="cover"
                        />
                        <PlayArrow
                          sx={{
                            fontSize: 48,
                            opacity: "50%",
                            position: "relative",
                            top: -75,
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </Box>
                    </Card>
                  </CardActionArea>
                  <Box
                    flexDirection={"column"}
                    marginBottom={theme.spacing(-2)}
                  >
                    <Tooltip title="Edit">
                      <Button
                        onClick={() =>
                          navigate("/courses/create", {
                            state: { courseId: c.id },
                          })
                        }
                      >
                        <Edit />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Invitations">
                      <Button
                        onClick={() =>
                          navigate("/courses/invitations", {
                            state: { courseId: c.id },
                          })
                        }
                      >
                        <Group />
                      </Button>
                    </Tooltip>
                    {org.role == "manager" ? (
                      <Tooltip title="Delete">
                        <Button onClick={() => setDeleteCourseId(c)}>
                          <Delete />
                        </Button>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Card>
              </Grid>
            ))}
        </Grid>
        {/* Confirmation modal for deleting course */}
        <Modal
          open={!!deleteCourseId?.id}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            style={{
              padding: theme.spacing(3),
              background: colors.backgroundSecondary,
            }}
          >
            <Typography>{t("deleteCourseConfirmation")}</Typography>
            <Typography>{deleteCourseId?.name}</Typography>
            <Button
              variant="text"
              style={{ paddingTop: theme.spacing(2) }}
              onClick={() => {
                if (deleteCourseId?.id) {
                  onDelete(deleteCourseId.id);
                  setDeleteCourseId(undefined);
                }
              }}
            >
              {t("ok")}
            </Button>
            <Button
              variant="text"
              style={{ paddingTop: theme.spacing(2) }}
              onClick={() => {
                setDeleteCourseId(undefined);
              }}
            >
              {t("cancel")}
            </Button>
          </Card>
        </Modal>
      </Box>
    </ViewContainer>
  );
};
