import { Box } from "@mui/material";
import { theme } from "../../theme";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { useEffect } from "react";

let Font = ReactQuill.Quill.import("formats/font");
Font.whitelist = ["Roboto", "Ubuntu", "Raleway"];
ReactQuill.Quill.register(Font, true);

export const TextContent = (props: {
  content: string;
  edit?: boolean;
  height?: string | number;
  width?: string | number;
  multiline?: boolean;
  title?: boolean;
  minimalEditor?: boolean;
  onValueChanged?: (val: string) => void;
  onSetReady?: () => void;
}) => {
  const { onSetReady } = props;
  useEffect(() => onSetReady && onSetReady(), [onSetReady]);

  if (!props.edit) {
    return (
      <>
        {props.content.split("\n").map((c, i) => (
          <Box
            key={`text-container-typocraphy-${i}`}
            display={"flex"}
            minHeight={theme.spacing(2)}
            height={props.height ? props.height : "100%"}
            width={
              props.width ? props.width : !props.title ? "100%" : undefined
            }
            minWidth={100}
            alignItems={props.title ? "center" : "flex-start"}
            justifyItems={"stretch"}
          >
            <ReactQuill
              key={`text-content-typocraphy-${i}`}
              value={c}
              readOnly
              theme="bubble"
              style={{
                flex: 1,
                overflow: props.title ? "hidden" : "auto",
                width: "100%",
                height: "100%",
                minWidth: 100,
              }}
            />
          </Box>
        ))}
      </>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: props.title ? "center" : "flex-start",
          alignItems: "stretch",
          height: props.title ? props.height : `100%`,
          outlineStyle: props.minimalEditor ? "solid" : "none",
          padding: 0,
          outlineWidth: 1,
          outlineColor: theme.palette.divider,
        }}
      >
        <ReactQuill
          theme={
            props.title ? "bubble" : props.minimalEditor ? "bubble" : "snow"
          } // Bubble tools on title, basic toolbar on body text.
          modules={
            props.title
              ? {
                  toolbar: [
                    "bold",
                    "italic",
                    { size: [false, "large", "huge"] },
                    "align",
                    { align: "center" },
                  ],
                } // Only bold and italic on title.
              : // Normal, large and huge sizes.
                // Left and center alignment.
                {
                  toolbar: [
                    // Most options on body text. No images or videos.
                    "bold",
                    "italic",
                    "underline",
                    "clean",
                    { color: [] },
                    { background: [] },
                    "link",
                    { list: "ordered" },
                    { list: "bullet" },
                    "align",
                    { align: "center" },
                    { align: "right" },
                    { size: ["small", false, "large", "huge"] },
                  ],
                }
          }
          style={{
            display: "flex",
            flex: props.title ? undefined : 1,
            flexDirection: "column",
            justifyContent: props.title ? "center" : "flex-start",
            alignItems: "stretch",
            height: props.title ? "auto" : undefined,
            borderStyle: props.title ? "solid" : "none",
            borderWidth: props.title ? 0 : 1,
            borderColor: theme.palette.divider,
            minWidth: props.minimalEditor ? 200 : 50,
            zIndex: props.title ? 10 : 9, // Keep title bubble menu on top
            overflow: "scroll",
          }}
          value={props.content}
          onChange={props.onValueChanged}
        />
      </Box>
    );
  }
};
