import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { ISlideProps } from "../slide";
import { theme } from "../../../theme";
import { SlideContentSelector } from "../SlideContentSelector";
import { TextContent } from "../slideTextContent";

export const OneElementWithTitleSlide = (props: ISlideProps) => {
  const { setReady } = props;

  const [contentsReady, setContentsReady] = useState([true, false]);

  const setReadyCallback = useCallback((index: number) => {
    setContentsReady((current) => {
      const newContentsReady = [...current];
      newContentsReady[index] = true;
      return newContentsReady;
    });
  }, []);

  useEffect(() => {
    let result = true;
    for (let i = 0; i < contentsReady.length; i++) {
      if (!contentsReady[i]) result = false;
    }
    if (result) setReady();
  }, [setReady, contentsReady]);

  const withBorder = {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    borderRadius: 1,
    marginRight: 1,
  };

  const withoutBorder = {
    borderWidth: 0,
  };

  return (
    <Grid container flex={1} flexGrow={1} padding={theme.spacing(2)}>
      <Grid
        item
        xs={12}
        display={"flex"}
        style={{
          height: theme.spacing(8),
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
          marginBottom: theme.spacing(1),
        }}
      >
        <TextContent
          content={(props.content && props.content[0]).data}
          onValueChanged={(val) => props.setContent(0, val)}
          edit={props.edit}
          minimalEditor
          multiline={false}
          title
          height={theme.spacing(8)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          ...(props.content && props.content[1].contentType !== "unknown"
            ? withoutBorder
            : withBorder),
          height: `calc(100% - ${theme.spacing(8)})`,
        }}
      >
        <SlideContentSelector
          content={props.content && props.content[1]}
          setContent={props.setContent}
          edit={props.edit}
          index={1}
          thumbnail={props.thumbnailSlide}
          onSetReady={setReadyCallback}
        />
      </Grid>
    </Grid>
  );
};
