import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Button } from "@mui/material";
import { theme } from "../../theme";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { t } from "i18next";
import { ImageRounded } from "@mui/icons-material";

var imageAutoOpenedTimestamp = new Date();

const getBase64 = async (file: File) => {
  return new Promise<string>((result, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      result(reader.result as string);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};

interface IImageUploadContentProps {
  onImageUpload: () => void;
  onImageUpdate: (index: number) => void;
  onFillModeChanged?: (mode: string) => void;
  imageList: any[];
  metadata: any;
  content: string;
  autoOpen?: boolean;
  showUploadBtn?: boolean;
  forcedFillMode?: string;
}

const ImageUploadContent = ({
  onImageUpload,
  onImageUpdate,
  onFillModeChanged,
  imageList,
  metadata,
  content,
  autoOpen,
  showUploadBtn,
  forcedFillMode,
}: IImageUploadContentProps) => {
  const [autoOpenImage, setAutoOpenImage] = useState(autoOpen);
  const upload = { onUpdate: onImageUpdate, onUpload: onImageUpload };
  const uploadRef = useRef(upload);
  useEffect(() => {
    uploadRef.current = {
      onUpdate: onImageUpdate,
      onUpload: onImageUpload,
    };
  }, [onImageUpdate, onImageUpload]);

  useEffect(() => {
    if (autoOpen) {
      setAutoOpenImage(autoOpen);
    }
  }, [autoOpen]);

  const hasImage = useRef(imageList.length > 0);
  useEffect(() => {
    hasImage.current = imageList.length > 0;
  }, [imageList.length]);

  useEffect(() => {
    if (
      autoOpenImage &&
      new Date().getTime() - imageAutoOpenedTimestamp.getTime() > 500
    ) {
      if (hasImage.current) uploadRef.current.onUpdate(0);
      else uploadRef.current.onUpload();
      imageAutoOpenedTimestamp = new Date();
    }
    setAutoOpenImage(false);
  }, [autoOpenImage]);

  return (
    <>
      {imageList.map((image: any) => (
        <img
          src={image["data_url"]}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: metadata.fillMode ? metadata.fillMode : "contain",
          }}
        />
      ))}
      {onImageUpload && showUploadBtn && (
        <Button
          style={{
            width: 150,
            height: 40,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: `translate(${-75}px, ${-20}px)`,
          }}
          variant="contained"
          onClick={() => {
            if (imageList.length > 0) onImageUpdate(0);
            else onImageUpload();
          }}
        >
          {content && content.length ? t("changeImage") : t("addImage")}
        </Button>
      )}
    </>
  );
};

interface IImageContentProps {
  content: string;
  edit?: boolean;
  onValueChanged?: (val: string) => void;
  onFillModeChanged?: (fillMode: string) => void;
  metadataStr?: string;
  onSetReady?: () => void;
  autoOpen?: boolean;
  showUploadBtn?: boolean;
  forcedFillMode?: string;
}

export const ImageContent = (props: IImageContentProps) => {
  const [images, setImages] = React.useState([]);
  const [enableImage, setEnableImage] = React.useState(true);

  const { onSetReady, showUploadBtn, autoOpen, content, forcedFillMode, onFillModeChanged } =
    props;
  useEffect(() => onSetReady && onSetReady(), [onSetReady]);

  const onChange = async (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    imageAutoOpenedTimestamp = new Date();

    // data for submit
    setImages(imageList as never[]);

    if (imageList[0].file && props.onValueChanged) {
      const base64 = await getBase64(imageList[0].file);
      props.onValueChanged(base64);
    }
  };

  const metadata = useMemo(
    () => (props.metadataStr ? JSON.parse(props.metadataStr) : {}),
    [props.metadataStr]
  );

  const imageUploadCallback = useCallback(
    ({
      imageList,
      onImageUpload,
      onImageRemoveAll,
      onImageUpdate,
      onImageRemove,
      forcedFillMode,
    }: any) => (
      <ImageUploadContent
        onImageUpdate={onImageUpdate}
        onImageUpload={onImageUpload}
        onFillModeChanged={onFillModeChanged}
        content={content}
        imageList={imageList}
        metadata={metadata}
        autoOpen={autoOpen}
        showUploadBtn={showUploadBtn}
      />
    ),
    [metadata, autoOpen, content, showUploadBtn, forcedFillMode, onFillModeChanged]
  );

  return (
    <Box width={"100%"} height={"100%"} position={"relative"}>
      {props.content && props.content.length && enableImage ? (
        <img
          src={props.content}
          alt=""
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            setEnableImage(false); // hide image on error
          }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: forcedFillMode
              ? forcedFillMode
              : metadata.fillMode
              ? metadata.fillMode
              : "contain",
          }}
        />
      ) : (
        <ImageRounded
          sx={{
            alignSelf: "center",
            color: theme.palette.primary.light,
            opacity: "50%",
            width: "80%",
            height: "80%",
            position: "absolute",
            top: "10%",
            left: "10%",
          }}
        />
      )}
      {props.edit && (
        <ImageUploading
          value={images}
          onChange={onChange}
          maxNumber={1}
          dataURLKey="data_url"
        >
          {imageUploadCallback}
        </ImageUploading>
      )}
    </Box>
  );
};
