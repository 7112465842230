interface IApiKeyProps {
  organisationId: string;
  userId?: string;
  courseId: string;
  apiKey?: string;
  password?: string;
}
export const getApiKeyHeaders = ({
  organisationId,
  userId,
  courseId,
  apiKey,
  password,
}: IApiKeyProps) => {
  return {
    "X-OrganisationId": organisationId,
    "X-UserId": userId,
    "X-CourseId": courseId,
    "X-ApiKey": apiKey || "",
    "X-Password": password || "",
  };
};
