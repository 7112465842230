import React, { useCallback, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { ISlideProps } from "../slide";
import { theme } from "../../../theme";
import { TextContent } from "../slideTextContent";
import { ImageContent } from "../slideImageContent";
import colors from "../../../colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import { mobileWidth } from "../../../styleUtils";
import { SlideContentSelector } from "../SlideContentSelector";

export const TwoColumnsWithTitleSlide = (props: ISlideProps) => {
  const narrowScreen = useMediaQuery(mobileWidth);
  const { setReady } = props;

  const [contentsReady, setContentsReady] = useState([true, false, false]);

  const setReadyCallback = useCallback((index: number) => {
    setContentsReady((current) => {
      const newContentsReady = [...current];
      newContentsReady[index] = true;
      return newContentsReady;
    });
  }, []);

  useEffect(() => {
    let result = true;
    for (let i = 0; i < contentsReady.length; i++) {
      if (!contentsReady[i]) result = false;
    }
    //console.log(contentsReady, result);
    if (result) setReady();
  }, [setReady, contentsReady]);

  const withBorder = {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    borderRadius: 1,
    marginRight: 1,
  };

  const withoutBorder = {
    borderWidth: 0,
    marginRight: 1,
  };

  return (
    <Grid
      container
      flex={1}
      flexGrow={1}
      sx={
        props.thumbnailSlide
          ? { overflow: "hidden" }
          : narrowScreen
          ? { overflow: "auto" }
          : {}
      }
      padding={theme.spacing(2)}
    >
      <Grid
        item
        xs={12}
        display={"flex"}
        style={{
          height: theme.spacing(8),
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
          marginBottom: theme.spacing(1),
        }}
      >
        <TextContent
          content={(props.content && props.content[0]).data}
          onValueChanged={(val) => props.setContent(0, val)}
          edit={props.edit}
          minimalEditor
          multiline={false}
          title
          height={theme.spacing(8)}
        />
      </Grid>
      <Grid
        item
        xs={narrowScreen ? 12 : 6}
        style={{
          height: props.thumbnailSlide
            ? "100%"
            : `calc(${narrowScreen ? "50" : "100"}% - ${theme.spacing(8)})`,
        }}
      >
        <Box
          height={"100%"}
          sx={
            props.content && props.content[1].contentType !== "unknown"
              ? withoutBorder
              : withBorder
          }
        >
          <SlideContentSelector
            content={props.content && props.content[1]}
            setContent={props.setContent}
            edit={props.edit}
            index={1}
            thumbnail={props.thumbnailSlide}
            onSetReady={setReadyCallback}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={narrowScreen ? 12 : 6}
        style={{
          height: `calc(${narrowScreen ? "50" : "100"}% - ${theme.spacing(8)})`,
        }}
      >
        <Box
          height={"100%"}
          sx={
            props.content && props.content[2].contentType !== "unknown"
              ? withoutBorder
              : withBorder
          }
        >
          <SlideContentSelector
            content={props.content && props.content[2]}
            setContent={props.setContent}
            edit={props.edit}
            index={2}
            thumbnail={props.thumbnailSlide}
            onSetReady={setReadyCallback}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
