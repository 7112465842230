import React, { useEffect } from "react";
import { Box, Input, TextField, Typography } from "@mui/material";
import colors from "../../colors";
import { theme } from "../../theme";

const getYoutubeId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match.length >= 2 && match[2].length > 10 ? match[2] : null;
};

const getVimeoIds = (url: string) => {
  const regExp = /^.*(vimeo.com\/)([a-zA-Z0-9]*)\/?([a-zA-Z0-9]*).*?/;
  const match = url.match(regExp);
  console.log(url, match);

  return match && match.length >= 3 ? [match[2], match[3]] : null;
};

const VideoContainer = ({ url }: { url: string }) => {
  if (url.startsWith("https://www.youtube.com/watch")) {
    url = `https://www.youtube.com/embed/${getYoutubeId(url)}`;
  }

  if (url.startsWith("https://vimeo.com/")) {
    const ids = getVimeoIds(url);
    if (ids)
      url = `https://player.vimeo.com/video/${ids[0]}?${
        ids[1] ? `h=${ids[1]}&amp;` : ""
      }badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`;
  }

  return (
    <>
      {url && url.startsWith("https://www.youtube") && (
        <iframe
          width={"100%"}
          height={"100%"}
          src={url}
          title={url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      )}
      {url && url.startsWith("https://player.vimeo") && (
        <iframe
          src={url}
          width={"100%"}
          height={"100%"}
          frameBorder="0"
          title={url}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </>
  );
};

export const VideoContent = (props: {
  content: string;
  edit?: boolean;
  onValueChanged?: (val: string) => void;
  onSetReady?: () => void;
}) => {
  const { onSetReady } = props;
  useEffect(() => onSetReady && onSetReady(), [onSetReady]);

  if (!props.edit) {
    return <VideoContainer url={props.content} />;
  } else {
    return (
      <Box flex={1} width={"100%"} position="relative">
        {props.content && props.content.startsWith("https://") && (
          <VideoContainer url={props.content} />
        )}
        <Box
          position={"absolute"}
          top="40%"
          left="10%"
          right="10%"
          bgcolor={"#333333dd"}
          alignItems={"center"}
          justifyContent={"center"}
          display={"flex"}
          flexDirection={"column"}
          padding={theme.spacing(2)}
        >
          <Typography
            variant="caption"
            marginBottom={theme.spacing(1)}
            color={"#fff"}
          >
            Syötä youtube tai vimeo videon url osoite
          </Typography>
          <TextField
            variant="outlined"
            label="URL"
            value={props.content}
            style={{ fontSize: 24 }}
            fullWidth
            className={"CenteredInput"}
            color={"primary"}
            onChange={(e) => {
              if (props.onValueChanged) props.onValueChanged(e.target.value);
            }}
          />
        </Box>
      </Box>
    );
  }
};
