import { IRole } from "@backend/model/role";
import { createSlice } from "@reduxjs/toolkit";

const organisationSlice = createSlice({
  name: "organisation",
  initialState: { organisation: undefined } as { organisation?: IRole },
  reducers: {
    setOrganisation(state, action) {
      state.organisation = action.payload;
    },
  },
  selectors: {
    getOrganisation: (state) => state.organisation,
  },
});

// Extract the action creators object and the reducer
const { actions, reducer, selectors } = organisationSlice;
// Extract and export each action creator by name
export const { setOrganisation } = actions;
export const { getOrganisation } = selectors;
// Export the reducer, either as a default or named export
export default reducer;
