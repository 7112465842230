import { IPublicClientApplication } from "@azure/msal-browser";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../config";

var msalInstance: IPublicClientApplication;

export const setBaseApiMsalInstance = (instance: IPublicClientApplication) => {
  msalInstance = instance;
};

export const baseAPI = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: config.baseUrl,
    prepareHeaders: async (headers: Headers) => {
      try {
        await msalInstance.initialize();
        if (msalInstance.getActiveAccount()) {
          const token = await msalInstance.acquireTokenSilent({ scopes: [] });
          headers.set("Authorization", `Bearer ${token.idToken}`);
          return headers;
        }
      } catch (err) {
        console.error(err);
      }
    },
  }),
  endpoints: () => ({}),
});
