import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useEditRoleMutation } from "../apis/rolesAPI";
import { getOrganisation } from "../slices/organisationSlice";
import { selector } from "../store";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { IRole, IUserStatusType } from "@backend/model/role";
import { ViewContainer } from "./components/viewContainer";
import { useTranslation } from "react-i18next";
import { useListSettingsQuery } from "../apis/settingsAPI";
import { isSettingEnabled } from "../utils/settingUtils";
import { theme } from "../theme";

export const EditPeople = (props: {
  person?: IRole;
  closeText?: string;
  onClose?: () => void;
  courseId?: string;
  canEditStatus?: boolean;
  lightMode?: boolean;
}) => {
  const org: IRole | undefined = selector((state) => getOrganisation(state));
  const { t } = useTranslation();
  const { state } = useLocation();
  const person = state?.person || props?.person;
  const role = useMemo(() => {
    return person?.role || "user";
  }, [person]);
  const organisationId: string | undefined =
    org?.organisationId || props?.person?.organisationId;

  const { onClose, courseId } = props;

  const minWidth = Math.min(400, window.innerWidth - 50);

  const navigate = useNavigate();

  const [userStatus, setUserStatus] = useState(person.userStatus || "employee");
  const [firstName, setFirstName] = useState(person.firstName);
  const [lastName, setLastName] = useState(person.lastName);
  const [email, setEmail] = useState(person.email);
  const [phoneNumber, setPhoneNumber] = useState(person.phoneNumber);
  const [company, setCompany] = useState(person.company);
  const [taxNumber, setTaxNumber] = useState(person.taxNumber);
  const [dateOfBirth, setDateOfBirth] = useState(
    person.dateOfBirth ? dayjs(person.dateOfBirth) : undefined
  );
  const [occupationalSafetyCard, setOccupationalSafetyCard] = useState<string>(
    person.occupationalSafetyCard
  );

  const { data: settings, isFetching: loadingSettings } = useListSettingsQuery({
    organisationId: organisationId || "",
  });

  const [error, setError] = useState(false);

  const [editRole] = useEditRoleMutation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserStatus(
      ((event.target as HTMLInputElement).value as IUserStatusType) ||
        "employee"
    );
  };

  const onBack = useCallback(() => {
    try {
      navigate(-1);
    } catch (err) {
      setError(true);
    }
  }, [navigate]);

  const onEdit = useCallback(async () => {
    if (organisationId) {
      try {
        await editRole({
          id: person.id,
          organisationId,
          firstName,
          lastName,
          email,
          company,
          phoneNumber,
          taxNumber,
          role,
          dateOfBirth: dateOfBirth ? dateOfBirth.toDate() : undefined,
          courseId,
          userStatus,
          occupationalSafetyCard,
        }).unwrap();
        if (onClose) onClose();
        else onBack();
      } catch (err) {
        setError(true);
        console.error(err);
      }
    }
  }, [
    editRole,
    organisationId,
    firstName,
    lastName,
    email,
    company,
    phoneNumber,
    taxNumber,
    dateOfBirth,
    courseId,
    userStatus,
    onBack,
    onClose,
    role,
    occupationalSafetyCard,
    person.id,
  ]);

  return (
    <ViewContainer loading={loadingSettings} overrideTheme={theme}>
      <Box
        flex={1}
        display={"flex"}
        flexDirection={"column"}
        position={"relative"}
        sx={{ paddingBottom: theme.spacing(4) }}
      >
        <Snackbar
          open={error}
          autoHideDuration={3000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => setError(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {t("errorOccured")}
          </Alert>
        </Snackbar>
        <Typography variant="h4">{t("personalInformation")}</Typography>
        <Divider style={{ width: "100%", marginBottom: theme.spacing(2) }} />

        {props.canEditStatus && (
          <FormControl>
            <FormLabel id="user-status-label">Status</FormLabel>
            <RadioGroup
              aria-labelledby="user-status-label"
              defaultValue="employee"
              name="user-status"
              value={userStatus}
              onChange={handleChange}
            >
              <FormControlLabel
                value={"employee"}
                control={<Radio />}
                label={t("employee")}
              />
              <FormControlLabel
                value={"contractor"}
                control={<Radio />}
                label={t("contractor")}
              />
              <FormControlLabel
                value={"visitor"}
                control={<Radio />}
                label={t("visitor")}
              />
            </RadioGroup>
          </FormControl>
        )}
        <TextField
          label={t("firstName")}
          margin="normal"
          style={{ minWidth }}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          label={t("lastName")}
          margin="normal"
          style={{ minWidth }}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          label={t("email")}
          disabled
          margin="normal"
          style={{ minWidth }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {settings && isSettingEnabled(settings, "phoneVisible") && (
          <TextField
            label={t("phoneNumber")}
            margin="normal"
            style={{ minWidth }}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        )}
        {settings && isSettingEnabled(settings, "companyVisible") && (
          <TextField
            label={t("company")}
            margin="normal"
            style={{ minWidth }}
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        )}
        {settings && isSettingEnabled(settings, "taxnumberVisible") && (
          <TextField
            label={t("taxNumber")}
            margin="normal"
            style={{ minWidth }}
            value={taxNumber}
            onChange={(e) => setTaxNumber(e.target.value)}
          />
        )}
        {settings &&
          isSettingEnabled(settings, "occupationalSafetyCardVisible") && (
            <TextField
              label={t("occupationalSafetyCard")}
              margin="normal"
              style={{ minWidth }}
              value={occupationalSafetyCard}
              onChange={(e) => setOccupationalSafetyCard(e.target.value)}
            />
          )}
        {settings && isSettingEnabled(settings, "dateOfBirthVisible") && (
          <DatePicker
            label={t("dateOfBirth")}
            value={dateOfBirth}
            sx={{ minWidth, marginTop: theme.spacing(2) }}
            onChange={(d) => setDateOfBirth(d || dayjs())}
          />
        )}
        <Button
          style={{ marginTop: theme.spacing(2), minWidth }}
          variant="contained"
          onClick={onEdit}
        >
          {t("save")}
        </Button>
        {props.closeText && (
          <Button
            style={{ marginTop: theme.spacing(2), minWidth }}
            variant="contained"
            onClick={props?.onClose || onBack}
          >
            {props.closeText}
          </Button>
        )}
      </Box>
    </ViewContainer>
  );
};
