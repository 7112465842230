// Need to use the React-specific entry point to allow generating React hooks
import { IRole } from "@backend/model/role";
import { baseAPI } from "./baseAPI";
import { getApiKeyHeaders } from "./apiUtils";

const enchancedAPI = baseAPI.enhanceEndpoints({ addTagTypes: ["roles"] });

// Define a service using a base URL and expected endpoints
export const rolesAPI = enchancedAPI.injectEndpoints({
  endpoints: (builder) => ({
    getOwnRole: builder.query<IRole[], any>({
      query: () => {
        return {
          url: "organisation",
        };
      },
    }),
    getSingleRole: builder.query<
      IRole,
      {
        organisationId: string;
        userId: string;
      }
    >({
      query: ({ organisationId, userId }) => {
        return {
          url: `organisation/${organisationId}/role/${userId}`,
        };
      },
      providesTags: ["roles"],
    }),
    getMissingFields: builder.query<
      string[],
      {
        organisationId: string;
        apiKey?: string;
        userId?: string;
        courseId?: string;
        password?: string;
      }
    >({
      query: ({ organisationId, apiKey, userId, courseId, password }) => ({
        url: `organisation/${organisationId}/role/${userId}/missingFields`,
        headers:
          apiKey && userId && courseId
            ? getApiKeyHeaders({
                courseId,
                userId,
                apiKey,
                organisationId,
                password,
              })
            : undefined,
      }),
      providesTags: ["roles"],
    }),
    listPeople: builder.query<IRole[], IRole>({
      query: ({ organisationId }) => {
        return {
          url: `organisation/${organisationId}/role`,
        };
      },
      providesTags: ["roles"],
    }),
    addRole: builder.mutation<any, IRole>({
      query: (params) => {
        return {
          url: `organisation/${params.organisationId}/role`,
          method: "POST",
          body: params,
        };
      },
      invalidatesTags: (res) => (res ? ["roles"] : []),
    }),
    editRole: builder.mutation<
      any,
      IRole & {
        apiKey?: string;
        courseId?: string;
        password?: string;
      }
    >({
      query: (params) => {
        return {
          url: `organisation/${params.organisationId}/role/${params.id}/edit`,
          method: "PUT",
          headers:
            params.apiKey && params.id && params.courseId
              ? getApiKeyHeaders({
                  courseId: params.courseId,
                  userId: params.id,
                  apiKey: params.apiKey,
                  organisationId: params.organisationId,
                  password: params.password,
                })
              : undefined,
          body: params,
        };
      },
      invalidatesTags: (res) => (res ? ["roles"] : []),
    }),
    deleteRole: builder.mutation<any, any>({
      query: ({ userId, organisationId }) => {
        return {
          url: `organisation/${organisationId}/role/${userId}/delete`,
          method: "DELETE",
        };
      },
      invalidatesTags: (res) => (res ? ["roles"] : []),
    }),
  }),
});

export const {
  useLazyGetOwnRoleQuery,
  useAddRoleMutation,
  useEditRoleMutation,
  useListPeopleQuery,
  useDeleteRoleMutation,
  useGetSingleRoleQuery,
  useGetMissingFieldsQuery,
} = rolesAPI;
