// Need to use the React-specific entry point to allow generating React hooks
import { IAddCourseDto } from "@backend/dto/addCourseDto";
import { baseAPI } from "./baseAPI";
import { getApiKeyHeaders } from "./apiUtils";
import type {
  ICourse,
  ICourseWithInvitation,
  ICourseInvitation,
} from "@backend/model/course";

const enchancedAPI = baseAPI.enhanceEndpoints({
  addTagTypes: ["courses", "invitations"],
});

// Define a service using a base URL and expected endpoints
export const coursesAPI = enchancedAPI.injectEndpoints({
  endpoints: (builder) => ({
    listCourses: builder.query<ICourse[], string>({
      query: (organisationId: string) =>
        `organisation/${organisationId}/courses`,
      providesTags: ["courses"],
    }),
    createCourse: builder.mutation<
      ICourse,
      { course: IAddCourseDto; organisationId: string }
    >({
      query: ({ course, organisationId }) => ({
        url: `organisation/${organisationId}/courses`,
        method: "POST",
        body: course,
      }),
      invalidatesTags: (res) => (res ? ["courses"] : []),
    }),
    updateCourse: builder.mutation<
      ICourse,
      { course: IAddCourseDto; organisationId: string; courseId: string }
    >({
      query: ({ course, organisationId, courseId }) => ({
        url: `organisation/${organisationId}/courses/${courseId}`,
        method: "PUT",
        body: course,
      }),
      invalidatesTags: (res) => (res ? ["courses"] : []),
    }),
    completeCourse: builder.mutation<
      any,
      {
        organisationId: string;
        courseId: string;
        userId: string;
        apiKey?: string;
        password?: string;
      }
    >({
      query: (props) => {
        const { organisationId, courseId, userId, apiKey } = props;
        return {
          url: `organisation/${organisationId}/courses/${courseId}/complete`,
          headers: apiKey && userId ? getApiKeyHeaders(props) : undefined,
          method: "POST",
          body: { userId },
        };
      },
      invalidatesTags: (res) => (res ? ["invitations"] : []),
    }),
    getCourseById: builder.query<
      ICourse,
      {
        organisationId: string;
        courseId: string;
        userId?: string;
        apiKey?: string;
        password?: string;
      }
    >({
      query: (props) => {
        const { organisationId, courseId, userId, apiKey } = props;
        return {
          url: `organisation/${organisationId}/courses/${courseId}`,
          headers: apiKey && userId ? getApiKeyHeaders(props) : undefined,
        };
      },
      providesTags: ["courses"],
    }),
    getInvitations: builder.query<
      ICourseInvitation[],
      { organisationId: string; courseId: string }
    >({
      query: ({ organisationId, courseId }) =>
        `organisation/${organisationId}/courses/${courseId}/invitations`,
      providesTags: ["invitations"],
    }),
    getCourseLink: builder.query<
      { url: string },
      { organisationId: string; courseId: string; userId: string }
    >({
      query: ({ organisationId, courseId, userId }) =>
        `organisation/${organisationId}/courses/${courseId}/link?userId=${userId}`,
    }),
    getCourseInformationByUserId: builder.query<
      ICourseWithInvitation[],
      { organisationId: string; userId: string }
    >({
      query: ({ organisationId, userId }) =>
        `organisation/${organisationId}/role/${userId}/invitations`,
      providesTags: ["invitations"],
    }),
    inviteToCourse: builder.mutation<
      any,
      { organisationId: string; courseId: string; emails: string[] }
    >({
      query: ({ organisationId, courseId, emails }) => {
        return {
          url: `organisation/${organisationId}/courses/${courseId}/invitations`,
          method: "POST",
          body: { emails },
        };
      },
      invalidatesTags: (res) => (res ? ["invitations"] : []),
    }),
    deleteCourse: builder.mutation<any, any>({
      query: ({ organisationId, courseId }) => {
        return {
          url: `organisation/${organisationId}/courses/${courseId}/delete`,
          method: "DELETE",
        };
      },
      invalidatesTags: (res) => (res ? ["courses", "invitations"] : []),
    }),
  }),
});

export const {
  useListCoursesQuery,
  useGetCourseInformationByUserIdQuery,
  useCreateCourseMutation,
  useUpdateCourseMutation,
  useGetCourseByIdQuery,
  useGetInvitationsQuery,
  useCompleteCourseMutation,
  useInviteToCourseMutation,
  useLazyGetCourseLinkQuery,
  useDeleteCourseMutation,
} = coursesAPI;
