import React, { useState } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CoursesIcon from "@mui/icons-material/List";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import AuthIcon from "@mui/icons-material/Lock";
import { Button, Card, Divider, Modal, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { theme } from "../theme";
import colors from "../colors";

const LeftMenu = (props: any) => {
  const { open } = props;
  const msal = useMsal();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [logoutConfirmation, setLogoutConfirmation] = useState<boolean>(
    false
  );

  return (
    <>
    <List>
      {[t("coursesTitle"), t("peopleTitle"), t("settings")].map(
        (text, index) => {
          let pageToNav = "";
          switch (text) {
            case t("coursesTitle"):
              pageToNav = "/courses";
              break;
            case t("peopleTitle"):
              pageToNav = "/people";
              break;
            case t("settings"):
              pageToNav = "/settings";
              break;
            default:
              pageToNav = "/";
          }
          return (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => {
                  navigate(pageToNav);
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {text === t("coursesTitle") && <CoursesIcon />}
                  {text === t("peopleTitle") && <PeopleIcon />}
                  {text === t("settings") && <SettingsIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          );
        }
      )}
      <Divider />
      <ListItemButton
        onClick={() => {
          setLogoutConfirmation(true)
        }}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          <AuthIcon />
        </ListItemIcon>
        <ListItemText primary={t("logout")} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </List>
    <Modal
    open={logoutConfirmation}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Card
      style={{
        padding: theme.spacing(3),
        background: colors.backgroundSecondary,
      }}
    >
      <Typography>
        {t('logoutConfirmation')}
      </Typography>
      <Button
        variant="text"
        style={{ paddingTop: theme.spacing(2) }}
        onClick={() => {
          if (logoutConfirmation) {
            msal.instance.logout();
            setLogoutConfirmation(false);
          }
        }}
      >
        {t('ok')}
      </Button>
      <Button
        variant="text"
        style={{ paddingTop: theme.spacing(2) }}
        onClick={() => {
          setLogoutConfirmation(false);
        }}
      >
        {t('cancel')}
      </Button>
    </Card>
  </Modal>
  </>
  );
};

export default LeftMenu;
