import { useMsal } from "@azure/msal-react";
import { Button, Card, Grid, Typography } from "@mui/material";
import { loginRequest } from "../authConfig";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ViewContainer } from "./components/viewContainer";

export const Login = () => {
  const msalInstance = useMsal().instance;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const autoLogin = useMemo(
    () => searchParams.get("autologin") === "true",
    [searchParams]
  );

  const handleRedirect = useCallback(() => {
    msalInstance
      .loginRedirect({
        ...loginRequest,
        prompt: "login",
      })
      .catch((error: any) => console.log(error));
  }, [msalInstance]);

  // autologin takes some time. We want to show spinner until it's ready
  useEffect(() => {
    const work = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoading(false);
    };
    work();
  }, []);

  useEffect(() => {
    if (autoLogin) handleRedirect();
  }, [autoLogin, handleRedirect]);

  return (
    <ViewContainer loading={loading}>
      {!loading && (
        <Grid
          direction="column"
          alignItems="center"
          justifyContent="center"
          container
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <Card
              style={{
                justifyContent: "center",
                alignItems: "center",
                padding: 24,
              }}
            >
              <Grid
                direction="column"
                alignItems="center"
                justifyContent="center"
                container
              >
                <Typography variant="h4" style={{ marginBottom: 12 }}>
                  inGuider
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleRedirect()}
                >
                  {t("login")}
                </Button>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
    </ViewContainer>
  );
};
