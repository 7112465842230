import { createTheme } from "@mui/material";
import { colors } from "./colors";

const themeConstructor = (colors: any) => {
  return createTheme({
    palette: {
      primary: {
        main: colors.themePrimary,
      },
      secondary: {
        main: colors.themeSecondary,
      },
      background: {
        default: colors.background,
        paper: colors.backgroundSecondary,
      },
      text: {
        primary: colors.textPrimary,
        secondary: colors.textSecondary,
      },
      common: {
        black: "#000",
        white: colors.textPrimary,
      },
      action: {
        active: colors.textPrimary,
        hover: colors.textHover,
        disabled: colors.textSecondary,
      },
      divider: "rgb(0,0,0,0.2)",
    },
    typography: {
      h6: {
        fontWeight: 400,
      },
      h5: {
        fontWeight: 400,
      },
      h4: {
        fontWeight: 300,
      },
      h3: {
        fontWeight: 400,
      },
      h2: {
        fontWeight: 400,
      },
      h1: {
        fontWeight: 400,
      },
      subtitle2: {
        // secondary gray color
        fontWeight: 400,
        color: colors.textSecondary,
      },
      button: {
        fontWeight: 400,
      },
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderWidth: 1,
            borderColor: colors.cardBorder,
            borderStyle: "solid",
            boxShadow: "0px 2px 3px 0px #222222",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              color: colors.borderDisabled,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .Mui-disabled": {
              color: colors.borderDisabled,
              "-webkit-text-fill-color": colors.borderDisabled,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.border, // Default border color
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.borderHover, // Border color on hover
            },
            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.borderDisabled, // Default border color,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
        },
      },
    },
  });
};

// Export themes for light and dark mode.
export const theme = themeConstructor(colors);
