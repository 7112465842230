import { Backdrop, Box, CircularProgress, Paper } from "@mui/material";
import { PropsWithChildren } from "react";
import { theme } from "../../theme";
import { Theme } from "@emotion/react";

export interface IViewContainerProps {
  loading: boolean;
  noScrolling?: boolean;
  noPadding?: boolean;
  overrideTheme?: Theme;
}

export const ViewContainer = (
  props: PropsWithChildren<IViewContainerProps>
) => {
  const { loading, noScrolling, noPadding } = props;

  const component = (
    <Box
      sx={{
        overflow: noScrolling ? "hidden" : "auto",
        flex: 1,
        padding: noPadding ? 0 : theme.spacing(4),
        height: "100%",
      }}
    >
      <Backdrop sx={{ color: "#ffff", zIndex: 10 }} open={loading}>
        <CircularProgress sx={{ alignSelf: "center" }} />
      </Backdrop>
      {props.children}
    </Box>
  );

  return component;
};
