import React, { useEffect, useState } from "react";
import { ViewContainer } from "./components/viewContainer";
import { Box, Button, Checkbox, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { theme } from "../theme";
import {
  useListSettingsQuery,
  useUpdateSettingsMutation,
} from "../apis/settingsAPI";
import { ISetting } from "@backend/model/setting";
import { getOrganisation } from "../slices/organisationSlice";
import { selector } from "../store";
import { isSettingEnabled } from "../utils/settingUtils";

const SettingsRow = (props: {
  label: string;
  value: boolean;
  onValueChanged: (val: boolean) => void;
}) => {
  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
      <Checkbox
        checked={props.value}
        onChange={() => props.onValueChanged(!props.value)}
        style={{ marginRight: theme.spacing(1) }}
      />
      <Typography variant="body1">{props.label}</Typography>
    </Box>
  );
};

export const SettingsView = () => {
  const { t } = useTranslation();
  const [isTaxNumberVisible, setIsTaxNumberVisible] = useState(false);
  const [isPhoneVisible, setIsPhoneVisible] = useState(false);
  const [isCompanyVisible, setIsCompanyVisible] = useState(false);
  const [isSafetyCardVisible, setIsSafetyCardVisible] = useState(false);
  const [isDateOfBirthVisible, setIsDateOfBirthVisible] = useState(false);
  const [saveSettings, { isLoading: saving }] = useUpdateSettingsMutation();
  const org = selector((state) => getOrganisation(state));
  const { data: settings, isFetching: loadingSettings } = useListSettingsQuery({
    organisationId: org?.organisationId || "",
  });

  useEffect(() => {
    if (!loadingSettings && settings) {
      setIsTaxNumberVisible(isSettingEnabled(settings, "taxnumberVisible"));
      setIsPhoneVisible(isSettingEnabled(settings, "phoneVisible"));
      setIsCompanyVisible(isSettingEnabled(settings, "companyVisible"));
      setIsSafetyCardVisible(
        isSettingEnabled(settings, "occupationalSafetyCardVisible")
      );
      setIsDateOfBirthVisible(isSettingEnabled(settings, "dateOfBirthVisible"));
    }
  }, [settings, loadingSettings]);

  return (
    <ViewContainer loading={saving}>
      <Typography variant="h4">{t("settings")}</Typography>
      <Divider
        style={{
          marginBottom: theme.spacing(2),
          marginTop: theme.spacing(2),
        }}
      />
      <Typography variant="h6">{t("peopleInfo")}</Typography>
      <SettingsRow
        label={t("isTaxNumberVisible")}
        onValueChanged={setIsTaxNumberVisible}
        value={isTaxNumberVisible}
      />
      <SettingsRow
        label={t("isPhoneVisible")}
        onValueChanged={setIsPhoneVisible}
        value={isPhoneVisible}
      />
      <SettingsRow
        label={t("isCompanyVisible")}
        onValueChanged={setIsCompanyVisible}
        value={isCompanyVisible}
      />
      <SettingsRow
        label={t("isSafetyCardVisible")}
        onValueChanged={setIsSafetyCardVisible}
        value={isSafetyCardVisible}
      />
      <SettingsRow
        label={t("isDateOfBirthVisible")}
        onValueChanged={setIsDateOfBirthVisible}
        value={isDateOfBirthVisible}
      />
      <Divider
        style={{
          marginBottom: theme.spacing(2),
          marginTop: theme.spacing(2),
        }}
      />
      <Button
        variant="contained"
        onClick={async () => {
          saveSettings({
            settings: [
              { type: "taxnumberVisible", value: isTaxNumberVisible },
              { type: "phoneVisible", value: isPhoneVisible },
              { type: "companyVisible", value: isCompanyVisible },
              {
                type: "occupationalSafetyCardVisible",
                value: isSafetyCardVisible,
              },
              { type: "dateOfBirthVisible", value: isDateOfBirthVisible },
            ] as ISetting[],
            organisationId: org?.organisationId || "",
          });
        }}
      >
        {t("save")}
      </Button>
    </ViewContainer>
  );
};
