import { useLocation } from "react-router-dom";
import { PeopleCourses } from "./peopleCourses";
import { ViewContainer } from "./components/viewContainer";
import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EditPeople } from "./editPeople";

export const PersonView = () => {
  const { state } = useLocation();
  const person = state?.person;
  const [mode, setMode] = useState<"edit" | "courses">("edit");
  const { t } = useTranslation();

  return (
    <ViewContainer loading={false} noPadding>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={mode === "edit" ? 0 : 1}
          onChange={(e: any) => setMode(e.target.id)}
        >
          <Tab label={t("edit")} id="edit" />
          <Tab label={t("courses")} id="courses" />
        </Tabs>
      </Box>

      {mode === "courses" ? <PeopleCourses person={person} /> : <></>}
      {mode === "edit" ? (
        <Box maxWidth={800}>
          <EditPeople canEditStatus person={person} />
        </Box>
      ) : (
        <></>
      )}
    </ViewContainer>
  );
};
