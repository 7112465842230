import { Box, Divider, Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getOrganisation } from "../slices/organisationSlice";
import { selector } from "../store";
import { useGetCourseInformationByUserIdQuery } from "../apis/coursesAPI";
import { ICourseWithInvitation } from "@backend/model/course";
import { theme } from "../theme";
import { numberOfLines } from "../styleUtils";
import dayjs from "dayjs";
import { ViewContainer } from "./components/viewContainer";
import { useTranslation } from "react-i18next";
import { IRole } from "@backend/model/role";

const InvitationItem = ({ item }: { item: ICourseWithInvitation }) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant="body1">{item.name}</Typography>
      </Grid>
      <Grid item xs={3} display={"flex"} alignItems={"center"}>
        <Typography sx={numberOfLines(3)} variant="subtitle2">
          {item.description}
        </Typography>
      </Grid>
      <Grid item xs={3} display={"flex"} alignItems={"center"}>
        <Typography variant="subtitle2">
          {item.invitation.lastCompleted
            ? dayjs(item.invitation.lastCompleted).format("DD.MM.YYYY")
            : t("Unfinished")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export const PeopleCourses = (props: { person?: IRole }) => {
  const org: any = selector((state) => getOrganisation(state));
  const { state } = useLocation();
  const person = (state?.person as IRole) || props?.person;
  const { t } = useTranslation();

  const { data: courses, isLoading: coursesLoading } =
    useGetCourseInformationByUserIdQuery(
      { organisationId: org?.organisationId || "", userId: person.id },
      {
        skip: !org,
      }
    );

  return (
    <ViewContainer loading={coursesLoading}>
      <Box width={"100%"} height={"100%"} position={"relative"}>
        <Typography variant="h4" style={{ paddingBottom: theme.spacing(2) }}>
          {t("coursesTitle")}
        </Typography>
        <Typography variant="h6" style={{ paddingBottom: theme.spacing(4) }}>
          {person.firstName} {person.lastName}
        </Typography>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="body1">{t("name")}</Typography>
          </Grid>
          <Grid item xs={3} display={"flex"} alignItems={"center"}>
            <Typography sx={numberOfLines(3)} variant="body1">
              {t("description")}
            </Typography>
          </Grid>
          <Grid item xs={3} display={"flex"} alignItems={"center"}>
            <Typography variant="body1">{t("completedDate")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        {courses && courses.map((p) => <InvitationItem item={p} />)}
      </Box>
    </ViewContainer>
  );
};
