import {
  Box,
  Button,
  CSSObject,
  Divider,
  IconButton,
  Theme,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { Home } from "./pages/home";
import { Courses } from "./pages/courses";
import { People } from "./pages/people";
import LeftMenu from "./pages/leftMenu";
import { theme } from "./theme";
import React from "react";
import { CreateCourse } from "./pages/createCourse";
import { PlayCourse } from "./pages/playCourse";
import { AddPeople } from "./pages/addPeople";
import { CourseInvitations } from "./pages/courseInvitations";
import { Login } from "./pages/login";
import { PeopleCourses } from "./pages/peopleCourses";
import { EditPeople } from "./pages/editPeople";
import { useTranslation } from "react-i18next";
import { PersonView } from "./pages/personView";
import { SettingsView } from "./pages/settings";
import { IRole } from "@backend/model/role";

const drawerWidth = 220;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const UnauthenticatedRoutes = () => {
  return (
    <BrowserRouter>
      <Box
        sx={{ display: "flex" }}
        width={"100vw"}
        height={"100vh"}
        padding={theme.spacing(2)}
      >
        <Routes>
          <Route index element={<Login />} />
          <Route path="courses/play" element={<PlayCourse />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Box>
    </BrowserRouter>
  );
};

export const AuthenticatedRoutes = ({
  organisation,
}: {
  organisation?: IRole;
}) => {
  const [open, setOpen] = React.useState(true);
  const { t, i18n } = useTranslation();

  const onChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <BrowserRouter>
      <Box sx={{ display: "flex" }} width={"100vw"} height={"100vh"}>
        <AppBar className="App-header" position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <ChevronRightIcon />
            </IconButton>
            <Box display={"flex"}>
              <Typography
                fontWeight={"bold"}
                variant="h6"
                noWrap
                component="div"
              >
                inGuider
              </Typography>
              <Typography variant="h6" marginLeft={theme.spacing(2)}>
                {organisation?.company}
              </Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                right: 0,
              }}
            >
              <Button
                color="inherit"
                aria-label="english"
                onClick={() => onChangeLanguage("en")}
              >
                EN
              </Button>
              <Button
                color="inherit"
                aria-label="finnish"
                onClick={() => onChangeLanguage("fi")}
              >
                FI
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon color="primary" />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <LeftMenu open={open} />
        </Drawer>
        <Box
          component="main"
          sx={{
            marginTop: theme.spacing(8),
            flexGrow: 1,
            flex: 1,
            alignContent: "stretch",
            justifyContent: "stretch",
            alignItems: "stretch",
            justifyItems: "stretch",
            display: "flex",
          }}
        >
          <Routes>
            <Route index element={<Courses />} />
            <Route path="courses" element={<Courses />} />
            <Route path="courses/create" element={<CreateCourse />} />
            <Route path="courses/play" element={<PlayCourse />} />
            <Route path="courses/invitations" element={<CourseInvitations />} />
            <Route path="people" element={<People />} />
            <Route path="people/add" element={<AddPeople />} />
            <Route path="people/edit" element={<EditPeople canEditStatus />} />
            <Route path="people/courses" element={<PeopleCourses />} />
            <Route path="people/person" element={<PersonView />} />
            <Route path="settings" element={<SettingsView />} />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  );
};
