import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { theme } from "../../theme";
import { t } from "i18next";
import { IRole } from "@backend/model/role";
import { DatePicker } from "@mui/x-date-pickers";

interface IMissingFieldsQueryProps {
  missingFields: string[];
  onSave: (data: IRole) => void;
}

export const MissingFieldsQuery = (props: IMissingFieldsQueryProps) => {
  const { missingFields } = props;

  const [data, setData] = useState<IRole | any>({});

  return (
    <Box display={"flex"} flexDirection={"column"} padding={theme.spacing(2)}>
      <Typography variant="h4">{t("missingFieldsTitle")}</Typography>
      {missingFields?.map((field) => {
        if (field === "dateOfBirth") {
          return (
            <DatePicker
              label={t(field)}
              sx={{ marginTop: theme.spacing(2) }}
              onChange={(d: any) =>
                setData(Object.assign({}, data, { [field]: d }))
              }
            />
          );
        } else {
          return (
            <TextField
              label={t(field)}
              style={{ marginTop: theme.spacing(2) }}
              onChange={(e) =>
                setData(Object.assign({}, data, { [field]: e.target.value }))
              }
            />
          );
        }
      })}
      <Button
        style={{ marginTop: theme.spacing(2) }}
        variant="contained"
        onClick={() => props.onSave(data)}
      >
        {t("save")}
      </Button>
    </Box>
  );
};
