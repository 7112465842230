const config = {
  baseUrl: "https://safety-master.azurewebsites.net/api/",
  publicCourseEmail: "test@inguider.fi",
  publicCourseUserId: "aa71805b-2fb4-4c55-8e0a-5d0a2be6aa9a",
  publicCoursePassword: "testingInguiderCourse",
};

// localhost
if (process.env.NODE_ENV === "development") {
  Object.assign(config, {
    baseUrl: "http://localhost:7071/api/",
  });
}
// development
else if (
  window.location.hostname.includes(
    "safetymasterwebhosttest.z16.web.core.windows.net"
  ) ||
  window.location.hostname.includes("app.test.inguider.fi")
) {
  Object.assign(config, {
    baseUrl: "https://safety-master-test.azurewebsites.net/api/",
  });
}

export default config;
