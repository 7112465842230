export const colors = {
  background: "#333",
  backgroundSecondary: "#252525",
  textPrimary: "#eee",
  textHover: "#aaa",
  textSecondary: "#999",
  themePrimary: "#3290E2",
  themeSecondary: "#399",
  border: "#999",
  borderHover: "#bbb",
  borderDisabled: "rgba(160,160,160,0.4)!important",
  cardBorder: "#222",
  settingsBoxColor: "#f5f5f5",
};

export default colors;
