export function toFloat(value: string | number | undefined | null): number {
  if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  } else {
    return 0;
  }
}

export function toInt(value: string | number | undefined | null): number {
  if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    const parsed = parseInt(value);
    return isNaN(parsed) ? 0 : parsed;
  } else {
    return 0;
  }
}
